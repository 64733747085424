import { mergeObject } from '../utils/object'
import { getTimeZone } from '../utils/datetime'
import { required } from '@vuelidate/validators'

import type {
  EnterpriseData, EnterpriseRules,
  EnterpriseAddress, EnterpriseAddressRules
} from 'shared-types/enterprise'

export function getInitialAddressData (addressData: Partial<EnterpriseAddress> = {}): EnterpriseAddress {
  const defaultData: EnterpriseAddress = {
    name: null,
    department: null,
    street1: '',
    street2: null,
    postalCode: '',
    city: '',
    state: null,
    country: ''
  }

  return mergeObject(defaultData, addressData)
}

export function getInitialAddressRules (): EnterpriseAddressRules {
  return {
    name: {},
    department: {},
    street1: { required },
    street2: {},
    postalCode: { required },
    city: { required },
    state: {},
    country: { required }
  }
}

export function getInitialEnterpriseData (enterpriseData: Partial<EnterpriseData> = {}): EnterpriseData {
  const defaultData: EnterpriseData = {
    id: '',
    keywords: '',
    name: '',
    configId: '',
    billing: {
      emails: [],
      address: null,
      info: null
    },
    address: getInitialAddressData(),
    subscription: 'professional',
    discount: null,
    timeZone: getTimeZone(),
    options: {},
    isDeleted: false,
    creationDate: '',
    updationDate: '',
    deletionDate: null
  }

  return mergeObject(defaultData, enterpriseData)
}

export function getInitialEnterpriseRules (): EnterpriseRules {
  return {
    id: {},
    keywords: {},
    name: { required },
    configId: { required },
    billing: {
      emails: { required },
      address: {},
      info: {}
    },
    address: getInitialAddressRules(),
    subscription: { required },
    discount: {},
    timeZone: { required },
    options: {},
    isDeleted: { required },
    creationDate: {},
    updationDate: {},
    deletionDate: {}
  }
}
