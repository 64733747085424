import { mergeObject } from '../utils/object'
import { required } from '@vuelidate/validators'

import type { UserData, UserRules } from 'shared-types/user'

export function getInitialUserData (userData: Partial<UserData> = {}): UserData {
  const defaultData: UserData = {
    id: '',
    enterpriseId: '',
    keywords: '',
    email: '',
    name: '',
    roles: [],
    options: {
      language: 'fr'
    },
    isDeleted: false,
    creationDate: '',
    updationDate: '',
    deletionDate: null
  }

  return mergeObject(defaultData, userData)
}

export function getInitialUserRules (): UserRules {
  return {
    id: {},
    enterpriseId: { required },
    keywords: {},
    email: { required },
    name: { required },
    roles: { required },
    options: {
      language: { required }
    },
    isDeleted: { required },
    creationDate: {},
    updationDate: {},
    deletionDate: {}
  }
}
